export default function PackagesList() {
  return (
    <>
      <h1 class="heading"> Our
        <span> packages</span> </h1>
      <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="box-container">
          <div class="box">
            <div><h5>ESSENTIAL</h5><h3>Rs.1800/*-Sq Ft</h3><div class="Home_packagescover__2aTub"></div></div>
            <div class="list packageLink">
              <ul>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        Design & Drawings
                      </button>
                    </h2>
                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <div class="packagescontent"><ul>
                          <li>2D – Floor Plans</li>
                          <li>3D Elevation Designs</li>
                        </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwo">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        Civil Construction
                      </button>
                    </h2>
                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <div class="packagescontent"><ul>
                          <li>Floor to floor height – 10 ft</li>
                          <li>Steel (500 TMT Bars) – Bhuwalka/Kamadhenu</li>
                          <li>Cement (Grade 53) – ACC/Birla/Penna/Dalmia</li>
                          <li>UG Sump built with solid blocks of 6” thickness &amp; waterproof plastered</li>
                          <li>Concrete – M20 grade RMC</li>
                          <li>Solid Block walls – 6” &amp; 4” thickness</li>
                          <li>Internal, external and toilet wall – column joints plastering with chicken mesh</li>
                          <li>Waterproofing compound – Dr.Fixit/FOSROC for external walls</li>
                          <li>Crystalline waterproofing, Cinder filling in sunken toilets, Heat &amp; Waterproofing in Terrace</li>
                          <li>Steel/concrete lofts – Not included</li>
                        </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingThree">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        Architectural Design
                      </button>
                    </h2>
                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <div class="packagescontent"><ul>
                          <li>Working drawing for all works</li>
                          <li>Schedule of Openings</li>
                          <li>Section and Elevation</li>
                        </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                        Interior Design
                      </button>
                    </h2>
                    <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <div class="packagescontent"><ul>
                          <li>2D Furniture layout</li>
                        </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingFive">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                        Structural Designing
                      </button>
                    </h2>
                    <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <div class="packagescontent">
                          <ul>
                            <li>Structural Design as per IS Code.</li>
                            <li>Good for construction drawings</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingSix">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                        MEP Designing
                      </button>
                    </h2>
                    <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body">
                        <div class="packagescontent">
                          <ul>
                            <li>Electrical Layout</li>
                            <li>Plumbing Water Line Layout</li>
                            <li>Plumbing Drainage Line Layout</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingSeven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                        Government Liaison Assistance
                      </button>
                    </h2>
                    <div id="flush-collapseSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <p>P2Construction will provide liaison assistance with different government agencies, obtain permissions, licenses &amp; sanction fee for the following (if applicable)</p>
                        <ul>
                          <li>Construction Plan Sanction</li>
                          <li>Temporary Electricity connection</li>
                          <li>Permanent electrical connection</li>
                          <li>Water connection</li>
                          <li>Sewage connection</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingEight">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                        Flooring & Wall tiling
                      </button>
                    </h2>
                    <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Living, Kitchen, Dining &amp; Bedrooms : Vitrified tiles – Rs.50/- per Sqft</li>
                          <li>Staircase &amp; Foyer Area : Granite – Rs.80/- per Sqft</li>
                          <li>Balcony, Sit-out, Passage Areas : Anti-skid tiles – Rs.35/- per Sqft</li>
                          <li>Toilet Flooring : Anti-skid tiles – Rs.40/- per Sqft</li>
                          <li>Toilet Wall Dado (7ft height) : Highlighting Tiles – Rs.40/- per Sqft</li>
                          <li>Parking Area : Parking tiles – Rs.35/- per Sqft</li>
                          <li>Kitchen counter top : Granite – Rs.100/- per Sqft</li>
                          <li>Kitchen Wall Dado (Up to 2’ above counter) : Backsplash tiles – Rs.35/- per Sqft</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingNine">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                        Painting
                      </button>
                    </h2>
                    <div id="flush-collapseNine" class="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Internal wall &amp; ceiling : wall putty + primer coat + latest Tractor emulsion paint</li>
                          <li>External walls : primer coat + ace weather proof paint</li>
                          <li>Brand : Asian/Berger/Dulux</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTen">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                        Electrical
                      </button>
                    </h2>
                    <div id="flush-collapseTen" class="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Light &amp; power points – as per electrical design</li>
                          <li>Geyser points – All bathrooms</li>
                          <li>AC provision – Any 1 room per floor</li>
                          <li>Conduits – Good quality, hidden in slabs and walls</li>
                          <li>Copper wire –Anchor</li>
                          <li>External electrification is included</li>
                          <li>Switches &amp; Plates – Anchor – Ziva make</li>
                          <li>A main DB &amp; MCB – IndoAsian make</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingEleven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEleven" aria-expanded="false" aria-controls="flush-collapseEleven">
                        Plumbing
                      </button>
                    </h2>
                    <div id="flush-collapseEleven" class="accordion-collapse collapse" aria-labelledby="flush-headingEleven" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Water supply system – Internal &amp; External</li>
                          <li>Connection for water inlet from corporation</li>
                          <li>External sewage system – Inspection chambers &amp; sewage outlet to site boundary</li>
                          <li>Class B CPVC pipes – Astral/Supreme make</li>
                          <li>Solar &amp; Geyser Provision</li>
                          <li>Rain water harvesting facility – Ground water recharge</li>
                          <li>PVC Overhead Tank – Ganga/Equivalent make</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwell">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwell" aria-expanded="false" aria-controls="flush-collapseTwell">
                        Fixtures
                      </button>
                    </h2>
                    <div id="flush-collapseTwell" class="accordion-collapse collapse" aria-labelledby="flush-headingTwell" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Kitchen sink with accessories : SS – Rs.4500/- per sink</li>
                          <li>CP &amp; Sanitary fixture such as Wash Basin, WC, Taps and other accessories like bottle trap, gratings etc.: Cera/Hindware/Equivalent – Rs.15,000/- per bathroom attached toilet and Rs.8,000/- per powder room</li>
                          <li>Electrical fixtures – Not Included</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingtheteen">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsetheteen" aria-expanded="false" aria-controls="flush-collapsetheteen">
                        Doors
                      </button>
                    </h2>
                    <div id="flush-collapsetheteen" class="accordion-collapse collapse" aria-labelledby="flush-headingtheteen" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Main Door : Teak Wood frame &amp; shutter including hardware – Rs.16,500/- per door</li>
                          <li>Internal Doors : Any wood frame &amp; shutter including cost of hardware – Rs.9,000/- per door</li>
                          <li>Internal Door frames &amp; shutters – Painted (only)</li>
                          <li>Main Door – Polished with melamine by hand</li>
                          <li>Bathroom Door – Frame &amp; shutter including hardware – Rs.7,500/- per door</li>
                          <li>Door hardware – Ozone/Europa/Dorma make.</li>
                          <li>Door hinges – SS hinges</li>
                          <li>Door &amp; Door Frame cost – Inclusive of planing, rebate, transportation etc.</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTFour" aria-expanded="false" aria-controls="flush-collapseTFour">
                        windows
                      </button>
                    </h2>
                    <div id="flush-collapseTFour" class="accordion-collapse collapse" aria-labelledby="flush-headingTFour" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Aluminium windows – 5mm clear glass with MS Grills</li>
                          <li>Basic price : Aluminium windows including MS grills – Rs 400/- per Sqft</li>
                          <li>Percentage of opening for windows – 12% Maximum</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTFive">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTFive" aria-expanded="false" aria-controls="flush-collapseTFive">
                        Fabrication
                      </button>
                    </h2>
                    <div id="flush-collapseTFive" class="accordion-collapse collapse" aria-labelledby="flush-headingTFive" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Main Gate: MS Gate – Rs.270/- per Sqft</li>
                          <li>Internal, External &amp; Balcony railing: MS railings – Rs.160/- per Sqft</li>
                          <li>Utility grill is not included in this package</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTSix">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTSix" aria-expanded="false" aria-controls="flush-collapseTSix">
                        Compound Wall
                      </button>
                    </h2>
                    <div id="flush-collapseTSix" class="accordion-collapse collapse" aria-labelledby="flush-headingTSix" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Readymade/ Pre-cast Compound wall – Maximum thickness of 3″</li>
                          <li>The compound wall is painted</li>
                          <li>Excavation, Foundation, PCC, Size Stone Masonry with DPC course above SSM</li>
                          <li>Height of compound above – 5 Feet above Plinth level</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTSeven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTSeven" aria-expanded="false" aria-controls="flush-collapseTSeven">
                        Elevation
                      </button>
                    </h2>
                    <div id="flush-collapseTSeven" class="accordion-collapse collapse" aria-labelledby="flush-headingTSeven" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>This proposal includes basic elevation design for your home</li>
                          <li>A 3D elevation is presented for client to choose from &amp; design</li>
                          <li>Elevation can be designed &amp; more elements may be added at very nominal cost to the design</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTEight">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTEight" aria-expanded="false" aria-controls="flush-collapseTEight">
                        Exclusions & Incidentals
                      </button>
                    </h2>
                    <div id="flush-collapseTEight" class="accordion-collapse collapse" aria-labelledby="flush-headingTEight" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>A design if needed to be modified more than thrice shall be charged for accordingly</li>
                          <li>Government fees for the liaison bodies to be paid directly by the client</li>
                          <li>The complete fee payment for plan sanction in Panchayat limits need to be borne by client</li>
                          <li>Assuming the site &amp; road level to be equal, if the ground level needs to be raised above 18”, it shall be charged for accordingly</li>
                          <li>Hard rock/soft rock excavation, if it arises shall be charged for accordingly</li>
                          <li>Buying new earth/sending existing earth for excavation/backfilling purpose shall be charged on actual</li>
                          <li>Basic price of Cement is Rs.350/- per bag &amp; Steel is Rs.50,000/- per MT. Any increase in these prices shall be charged accordingly</li>
                          <li>Any work not mentioned within this package or outside the site boundary shall be charged for accordingly</li>
                          <li>One point of Electricity &amp; water needs to be provided by the client free of cost to facilitate construction</li>
                          <li>SBC of soil is considered at 180 KN/M2 and depth of the foundation considered at 5 Feet, any increase in depth shall be charged accordingly on actuals upon site conditions</li>
                          <li>If there is constraints in material delivery or socking place and materials to be order in small quantities, the additional charges for the same shall be charged on actuals</li>
                          <li>A specific area to construct labour and material shed needs to be provided within 50 feet from the construction place by the client</li>
                          <li>Any road cutting charges for the connection of Electricity and Sewage lines is not included, such works shall be charged additionally</li>
                        </ul>
                        <p>NOTE: The above mentioned material specifications are generalized, the inclusion or the exclusion of the same shall vary depending on the floor plan.</p>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTNine">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTNine" aria-expanded="false" aria-controls="flush-collapseTNine">
                        Payment Structure
                      </button>
                    </h2>
                    <div id="flush-collapseTNine" class="accordion-collapse collapse" aria-labelledby="flush-headingTNine" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>A customized payment schedule will be shared based on project specifications.</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingTwenty">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwenty" aria-expanded="false" aria-controls="flush-collapseTwenty">
                        Warranty and Guarantee
                      </button>
                    </h2>
                    <div id="flush-collapseTwenty" class="accordion-collapse collapse" aria-labelledby="flush-headingTwenty" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Construction guarantee – 1 Year ; Waterproofing warranty – 10 Years</li>
                          <li>Guarantee &amp; warranty is provided on Stamp paper</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <a
              href="#contact"
              class="p2ButtonStyle">Get a custom quote</a>
          </div>

          <div class="box">
            <div><h5>PREMIUM</h5><h3>Rs.1950/*-Sq Ft</h3><div class="Home_packagescover__2aTub"></div></div>
            <div class="list packageLink">
              <ul>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2One">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2One" aria-expanded="false" aria-controls="flush-collapse2One">
                        Design & Drawings
                      </button>
                    </h2>
                    <div id="flush-collapse2One" class="accordion-collapse collapse" aria-labelledby="flush-heading2One" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>2D – Floor Plans</li>
                          <li>3D Elevation Designs</li>
                          <li>Internal Isometric Views all rooms, open areas etc</li>
                          <li>Hands on isometric 3D model of the home with separate floors</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2Two">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2Two" aria-expanded="false" aria-controls="flush-collapse2Two">
                        Civil Construction
                      </button>
                    </h2>
                    <div id="flush-collapse2Two" class="accordion-collapse collapse" aria-labelledby="flush-heading2Two" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Floor to floor height – 10’6”</li>
                          <li>Steel (500 TMT bars) – Bhuwalka/Kamadhenu</li>
                          <li>Cement (Grade 53) – ACC/Birla/Penna/Dalmia</li>
                          <li>UG Sump built with solid blocks of 6” thickness &amp; waterproof plastered</li>
                          <li>Concrete – M20 grade RMC</li>
                          <li>Solid Block walls – 6” &amp; 4” thickness</li>
                          <li>Internal, external and toilet wall – column joints plastering with chicken mesh</li>
                          <li>Waterproofing compound – Dr.Fixit/FOSROC for external walls</li>
                          <li>Crystalline waterproofing, Cinder filling in sunken toilets, Heat &amp; Waterproofing in Terrace</li>
                          <li>Steel/concrete lofts – Not Included</li>
                          <li>Ledge wall for incorporating concealed sanitary parts – Included</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2Three">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2Three" aria-expanded="false" aria-controls="flush-collapse2Three">
                        Architectural Design
                      </button>
                    </h2>
                    <div id="flush-collapse2Three" class="accordion-collapse collapse" aria-labelledby="flush-heading2Three" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Working drawing for all works</li>
                          <li>Schedule of Openings</li>
                          <li>Section and Elevation</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2Four">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2Four" aria-expanded="false" aria-controls="flush-collapse2Four">
                        Interior Design
                      </button>
                    </h2>
                    <div id="flush-collapse2Four" class="accordion-collapse collapse" aria-labelledby="flush-heading2Four" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Complete 3D design interiors, only if executed by P2Construction.</li>
                          <li>2D Furniture layout</li>
                          <li>Assisting in interior procurement</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2Five">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2Five" aria-expanded="false" aria-controls="flush-collapse2Five">
                        Structural Designing
                      </button>
                    </h2>
                    <div id="flush-collapse2Five" class="accordion-collapse collapse" aria-labelledby="flush-heading2Five" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Structural Design as per IS Code.</li>
                          <li>Good for construction drawings</li>
                          <li>Soil Test report</li>
                          <li>Design Analysis Report</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2Six">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2Six" aria-expanded="false" aria-controls="flush-collapse2Six">
                        MEP Designing
                      </button>
                    </h2>
                    <div id="flush-collapse2Six" class="accordion-collapse collapse" aria-labelledby="flush-heading2Six" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Electrical Layout</li>
                          <li>Plumbing Water Line Layout</li>
                          <li>Plumbing Drainage Line Layout</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2Seven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2Seven" aria-expanded="false" aria-controls="flush-collapseSeven">
                        Government Liaison Assistance
                      </button>
                    </h2>
                    <div id="flush-collapse2Seven" class="accordion-collapse collapse" aria-labelledby="flush-heading2Seven" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <p>P2Construction will provide liaison assistance with different government agencies, obtain permissions, licenses &amp; sanction fee for the following (if applicable)</p>
                        <ul>
                          <li>Construction Plan Sanction</li>
                          <li>Temporary Electricity connection</li>
                          <li>Permanent electrical connection</li>
                          <li>Water connection</li>
                          <li>Sewage connection</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2Eight">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2Eight" aria-expanded="false" aria-controls="flush-collapse2Eight">
                        Flooring & Wall tiling
                      </button>
                    </h2>
                    <div id="flush-collapse2Eight" class="accordion-collapse collapse" aria-labelledby="flush-heading2Eight" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Kitchen &amp; Bedrooms : Vitrified Tiles – Rs.65/- per Sqft</li>
                          <li>Living Room, Dining : Marble finished vitrified tiles -Rs.70/- per Sqft</li>
                          <li>Staircase : Granite – Rs.100/- per Sqft</li>
                          <li>Balcony, Sit-out, Passage &amp; Common areas : Anti-skid tiles – Rs.50/- per Sqft</li>
                          <li>Toilet Floor : Anti-skid tiles – Rs.45/- per Sqft</li>
                          <li>Toilet Wall Dado (Up to 7ft height) : Light &amp; Highlighting tiles – Rs.50/- per Sqft</li>
                          <li>Parking Area : Parking tiles – Rs.40/- per Sqft</li>
                          <li>Kitchen counter top : Granite – Rs.120/- per Sqft</li>
                          <li>Kitchen Wall Dado (Up to 2’ above counter) : Backsplash tiles – Rs.55/- per Sqft</li>
                          <li>Puja Room- Wall tiling – Not Included</li>
                          <li>Puja Room- Granite slab- Rs-100/- per Sqft</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2Nine">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2Nine" aria-expanded="false" aria-controls="flush-collapse2Nine">
                        Painting
                      </button>
                    </h2>
                    <div id="flush-collapse2Nine" class="accordion-collapse collapse" aria-labelledby="flush-heading2Nine" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Internal wall &amp; ceiling : Wall putty + primer coat + latest ‘Tractor Shine’ paint.</li>
                          <li>External walls : Primer coat + external Apex weather proof paint</li>
                          <li>Brand : Asian/Berger/Dulux</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2Ten">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2Ten" aria-expanded="false" aria-controls="flush-collapse2Ten">
                        Electrical
                      </button>
                    </h2>
                    <div id="flush-collapse2Ten" class="accordion-collapse collapse" aria-labelledby="flush-heading2Ten" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Light &amp; power points – as per electrical design</li>
                          <li>Geyser points – All bathrooms</li>
                          <li>AC provision – Any 2 rooms</li>
                          <li>Conduits – Good quality, hidden in slabs and walls</li>
                          <li>Fire proof copper wire – Finolex or Polycab</li>
                          <li>External electrification is included</li>
                          <li>Switches &amp; Plates – Anchor-Roma make</li>
                          <li>A main DB &amp; MCB – IndoAsian/Siemens/Equivalent make</li>
                          <li>Electric Car charging point – Parking area</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2Eleven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2Eleven" aria-expanded="false" aria-controls="flush-collapse2Eleven">
                        Plumbing
                      </button>
                    </h2>
                    <div id="flush-collapse2Eleven" class="accordion-collapse collapse" aria-labelledby="flush-heading2Eleven" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Water supply system – Internal &amp; External</li>
                          <li>Connection for water inlet from corporation</li>
                          <li>External sewage system – Inspection chambers &amp; sewage outlet to site boundary</li>
                          <li>Class B CPVC pipes – Ashirwad/Astral make</li>
                          <li>Solar &amp; Geyser Provision</li>
                          <li>Rain water harvesting facility – Ground water recharge</li>
                          <li>PVC Overhead Tank – Ganga or Equivalent make</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2Twell">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2Twell" aria-expanded="false" aria-controls="flush-collapse2Twell">
                        Fixtures
                      </button>
                    </h2>
                    <div id="flush-collapse2Twell" class="accordion-collapse collapse" aria-labelledby="flush-heading2Twell" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Kitchen sink : SS/Granite/Carysil – Rs. 5,000/- per sink</li>
                          <li>Kitchen accessories – Rs. 3,000/-</li>
                          <li>CP &amp; Sanitary fixtures such as Wash Basin, WC, Taps and other accessories like bottle trap, gratings etc. : Jaquar/Hindware – Rs.22,000/- per bathroom attached toilet and Rs.10,000/- per powder room</li>
                          <li>Electrical Fixtures – Not included</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2theteen">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2theteen" aria-expanded="false" aria-controls="flush-collapse2theteen">
                        Doors
                      </button>
                    </h2>
                    <div id="flush-collapse2theteen" class="accordion-collapse collapse" aria-labelledby="flush-heading2theteen" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <div><ul>
                          <li>Main, Puja Door – Teak Wood frame &amp; shutter including hardware – Rs.25,000/- per door</li>
                          <li>Internal Door – Sal wood frame &amp; flush shutter including hardware – Rs.10,000/- per door</li>
                        </ul>
                          <ul>
                            <li>Internal door frames &amp; shutter – Painted (only)</li>
                            <li>Main, Puja Door – Polished with melamine spray</li>
                            <li>Bathroom Door – Frame &amp; shutter including hardware – Rs.7,500/- per door</li>
                            <li>Door Hardware – Ozone/Europa/Dorma make</li>
                            <li>Door Hinges – SS hinges</li>
                            <li>Door &amp; Door Frame cost – Inclusive of planing, rebate, transportation etc.</li>
                          </ul>
                        </div>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2TFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2TFour" aria-expanded="false" aria-controls="flush-collapse2TFour">
                        windows
                      </button>
                    </h2>
                    <div id="flush-collapse2TFour" class="accordion-collapse collapse" aria-labelledby="flush-heading2TFour" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>UPVC windows – 5mm clear glass with MS Grills</li>
                          <li>Basic price : uPVC with MS grill – Rs.550/- per Sqft (If required, upgrade to wooden windows can be made at an additional cost per Sqft, measured on built-up area. The additional cost can be calculated based on the wooden material chosen by the Client.)</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2TFive">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2TFive" aria-expanded="false" aria-controls="flush-collapse2TFive">
                        Fabrication
                      </button>
                    </h2>
                    <div id="flush-collapse2TFive" class="accordion-collapse collapse" aria-labelledby="flush-heading2TFive" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Main Gate : MS Gate – Rs. 360/- per Sqft</li>
                          <li>External staircase &amp; balcony railing : MS Railing – Rs. 160/- per Sqft</li>
                          <li>Internal staircase railing : SS Railing – Rs.260/- per Sqft</li>
                          <li>Utility grill shall be provided up to 120 Sqft in any one floor home only</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2TSix">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2TSix" aria-expanded="false" aria-controls="flush-collapse2TSix">
                        Compound Wall
                      </button>
                    </h2>
                    <div id="flush-collapse2TSix" class="accordion-collapse collapse" aria-labelledby="flush-heading2TSix" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Compound wall – Solid block walls of 4” thickness to suit the elevation.</li>
                        </ul>
                        <ul>
                          <li>Excavation, Foundation, PCC, Size Stone Masonry with DPC course above SSM</li>
                          <li>Height of Compound wall – 5 Feet from Plinth Levels</li>
                          <li>Compound wall – Plastered &amp; Painted</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2TSeven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2TSeven" aria-expanded="false" aria-controls="flush-collapse2TSeven">
                        Elevation
                      </button>
                    </h2>
                    <div id="flush-collapse2TSeven" class="accordion-collapse collapse" aria-labelledby="flush-heading2TSeven" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>This proposal includes aethetically pleasing elevation</li>
                          <li>A 3D elevation is presented for client to choose from &amp; design further</li>
                          <li>Elevation budget – 1% of the Project value.</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2TEight">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2TEight" aria-expanded="false" aria-controls="flush-collapse2TEight">
                        Exclusions & Incidentals
                      </button>
                    </h2>
                    <div id="flush-collapse2TEight" class="accordion-collapse collapse" aria-labelledby="flush-heading2TEight" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li><b></b>A design if needed to be modified more than thrice shall be charged for accordingly</li>
                          <li>Government fees for the liaison bodies to be paid directly by the client</li>
                        </ul>
                        <ul>
                          <li>The complete fee payment for plan sanction in Panchayat limits need to be borne by client</li>
                          <li>Assuming the site &amp; road level to be equal, if the ground level needs to be raised above 18”, it shall be charged for accordingly</li>
                          <li>Hard rock/soft rock excavation, if it arises shall be charged for accordingly</li>
                          <li>Buying new earth/sending existing earth for excavation/backfilling purpose shall be charged on actual</li>
                          <li>Basic price of Cement is Rs.350/- per bag &amp; Steel is Rs.50,000/- per MT. Any increase in these prices shall be charged accordingly</li>
                          <li>Percentage of opening for doors &amp; windows – 22% of built up area Maximum</li>
                          <li>Any work not mentioned within this package or outside the site boundary shall be charged for accordingly</li>
                          <li>One point of Electricity &amp; water needs to be provided by the client free of cost to facilitate construction</li>
                          <li>SBC of soil is considered at 180 KN/M2 and depth of the foundation considered at 5 Feet, any increase in depth shall be charged accordingly on actuals upon site conditions</li>
                          <li>If there is constraints in material delivery or socking place and materials to be order in small quantities, the additional charges for the same shall be charged on actuals</li>
                          <li>A specific area to construct labour and material shed needs to be provided within 50 feet from the construction place by the client</li>
                          <li>Any road cutting charges for the connection of Electricity and Sewage lines is not included, such works shall be charged additionally</li>
                        </ul>
                        <p>NOTE: The above mentioned material specifications are generalized, the inclusion or the exclusion of the same shall vary depending on the floor plan.</p>

                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2TNine">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2TNine" aria-expanded="false" aria-controls="flush-collapse2TNine">
                        Payment Structure
                      </button>
                    </h2>
                    <div id="flush-collapse2TNine" class="accordion-collapse collapse" aria-labelledby="flush-heading2TNine" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>A customized payment schedule will be shared based on the project specifications.</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading2Twenty">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse2Twenty" aria-expanded="false" aria-controls="flush-collapse2Twenty">
                        Warranty and Guarantee
                      </button>
                    </h2>
                    <div id="flush-collapse2Twenty" class="accordion-collapse collapse" aria-labelledby="flush-heading2Twenty" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Construction guarantee – 1 Year ; Waterproofing warranty – 10 Years</li>
                          <li>Guarantee &amp; warranty is provided on Stamp paper</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <a
              href="#contact"
              class="p2ButtonStyle">Get a custom quote</a>
          </div>
          <div class="box">
            <div><h5>LUXURIOUS</h5><h3>Rs.2100/*-Sq Ft</h3><div class="Home_packagescover__2aTub"></div></div>
            <div class="list packageLink">
              <ul>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3One">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3One" aria-expanded="false" aria-controls="flush-collapse3One">
                        Design & Drawings
                      </button>
                    </h2>
                    <div id="flush-collapse3One" class="accordion-collapse collapse" aria-labelledby="flush-heading3One" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Premium 2D &amp; 3D – Floor Plans</li>
                          <li>Luxury 3D Elevation Designs of all views</li>
                          <li>Internal Isometric Views all rooms, open areas etc &amp; detailed renders for each segment of the home for better understanding of the design</li>
                          <li>Detailed Landscaping design</li>
                          <li>3D Walkthrough of the home along with internal &amp; external walkthrough</li>
                          <li>Hands on isometric 3D model of the home with separate floors</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3Two">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3Two" aria-expanded="false" aria-controls="flush-collapse3Two">
                        Civil Construction
                      </button>
                    </h2>
                    <div id="flush-collapse3Two" class="accordion-collapse collapse" aria-labelledby="flush-heading3Two" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Floor to floor height – 10’6”</li>
                          <li>Steel (500 TMT bars) – JSW/SAIL/Vizag</li>
                          <li>Cement (Grade 53) – ACC/ Ultratech</li>
                          <li>UG Sump built with RCC &amp; waterproof plastered</li>
                          <li>Concrete – M20 grade</li>
                          <li>Solid Block walls (of APCO or similar) – 6” &amp; 4” thickness</li>
                          <li>Internal courtyards, skylights other designer architectural elements are included</li>
                        </ul>
                        <ul>
                          <li>Internal, external and toilet wall – column joints plastering with chicken mesh</li>
                          <li>Waterproofing compound – Dr.Fixit/FOSROC for external walls</li>
                          <li>Crystalline waterproofing, Cinder filling in sunken toilets, Heat &amp; Waterproofing in Terrace</li>
                          <li>Ledge wall for incorporating concealed sanitary parts is included</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3Three">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3Three" aria-expanded="false" aria-controls="flush-collapse3Three">
                        Architectural Design
                      </button>
                    </h2>
                    <div id="flush-collapse3Three" class="accordion-collapse collapse" aria-labelledby="flush-heading3Three" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Working drawing for all works</li>
                          <li>Schedule of Openings</li>
                          <li>Section and Elevation</li>
                          <li>Detailed portfolio of the home post handover</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3Four">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3Four" aria-expanded="false" aria-controls="flush-collapse3Four">
                        Interior Design
                      </button>
                    </h2>
                    <div id="flush-collapse3Four" class="accordion-collapse collapse" aria-labelledby="flush-heading3Four" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Complete 3D design interiors, only if executed by P2Construction.</li>
                          <li>2D furniture layout.</li>
                          <li>Assisting in interior procurement</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3Five">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3Five" aria-expanded="false" aria-controls="flush-collapseFive">
                        Structural Designing
                      </button>
                    </h2>
                    <div id="flush-collapse3Five" class="accordion-collapse collapse" aria-labelledby="flush-heading3Five" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Structural Design as per IS Code.</li>
                          <li>Good for construction drawings</li>
                          <li>Soil Test Report</li>
                          <li>Overalapped Structural, Architectural &amp; MEP Drawings</li>
                          <li>3D stuctural drawings &amp; Design Analysis Report</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3Six">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3Six" aria-expanded="false" aria-controls="flush-collapse3Six">
                        MEP Designing
                      </button>
                    </h2>
                    <div id="flush-collapse3Six" class="accordion-collapse collapse" aria-labelledby="flush-heading3Six" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Electrical Layout</li>
                          <li>Plumbing Water Line Layout</li>
                          <li>Plumbing Drainage Line Layout</li>
                          <li>Deatiled HVAC design</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3Seven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3Seven" aria-expanded="false" aria-controls="flush-collapse3Seven">
                        Government Liaison Assistance
                      </button>
                    </h2>
                    <div id="flush-collapse3Seven" class="accordion-collapse collapse" aria-labelledby="flush-heading3Seven" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <p>P2Construction will provide liaison assistance with different government agencies, obtain permissions, licenses &amp; sanction fee for the following (if applicable)</p>
                        <ul>
                          <li>Construction Plan Sanction</li>
                          <li>Temporary Electricity connection</li>
                          <li>Permanent electrical connection</li>
                          <li>Water connection</li>
                          <li>Sewage connection</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3Eight">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3Eight" aria-expanded="false" aria-controls="flush-collapse3Eight">
                        Flooring & Wall tiling
                      </button>
                    </h2>
                    <div id="flush-collapse3Eight" class="accordion-collapse collapse" aria-labelledby="flush-heading3Eight" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Kitchen &amp; Bedrooms – Vitrified Tiles – Rs.90/- per Sqft</li>
                          <li>Main Living Room, Dining – Italian Marble/Premium Finished Vitrified tiles of 4’x8’ dimension -Rs.350/- per Sqft</li>
                          <li>Staircase &amp; Foyer Area – Granite – Rs.120/- per Sqft</li>
                          <li>Balcony, Sit-out, Passage Areas – Anti-skid tiles – Rs.60/- per Sqft</li>
                          <li>Toilet Floor – Anti-skid tiles – Rs.65/- per Sqft</li>
                          <li>Toilet Wall Dado (Full height) – Light &amp; Highlighting tiles – Rs.70/- per Sqft</li>
                        </ul>
                        <ul>
                          <li>Parking Area – Parking tiles/Designer Pavers – Rs.55/- per Sqft</li>
                          <li>Kitchen counter top – Granite – Rs.150/- per Sqft</li>
                          <li>Kitchen Wall Dado (Up to 2’ above counter) – Backsplash tiles – Rs.60/- per Sqft</li>
                          <li>Puja Room- Wall tiling – Not Included</li>
                          <li>Puja Room- Granite slab- Rs-100/- per Sqft</li>
                        </ul>
                      </div>
                      </div></div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3Nine">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3Nine" aria-expanded="false" aria-controls="flush-collapse3Nine">
                        Painting
                      </button>
                    </h2>
                    <div id="flush-collapse3Nine" class="accordion-collapse collapse" aria-labelledby="flush-heading3Nine" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Internal wall &amp; ceiling – wall putty + primer coat + latest Premium Emulsion paint</li>
                          <li>External walls – primer coat + external Apex Ultima weather proof paint</li>
                          <li>Brand – Asian/Berger/Dulux</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3Ten">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3Ten" aria-expanded="false" aria-controls="flush-collapse3Ten">
                        Electrical
                      </button>
                    </h2>
                    <div id="flush-collapse3Ten" class="accordion-collapse collapse" aria-labelledby="flush-heading3Ten" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Light &amp; power points – as per client requirements</li>
                          <li>Conduits – Good quality, hidden in slabs and walls</li>
                          <li>Fire retardant copper wire – Havells</li>
                          <li>External electrification is included</li>
                          <li>Switches &amp; Plates – Legrand/ABB make (Touch screen switch provision only)</li>
                          <li>A main DB &amp; MCB – ABB/Schneider/Legrand make</li>
                          <li>Provision for home automation is included</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3Eleven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3Eleven" aria-expanded="false" aria-controls="flush-collapse3Eleven">
                        Plumbing
                      </button>
                    </h2>
                    <div id="flush-collapse3Eleven" class="accordion-collapse collapse" aria-labelledby="flush-heading3Eleven" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Water supply system – Internal &amp; External Connection for water inlet from corporation</li>
                          <li>External sewage system – Inspection chambers &amp; sewage outlet to site boundary</li>
                          <li>Class B CPVC pipes – Ashirwad/Astral make</li>
                          <li>Solar &amp; Geyser Provision</li>
                          <li>Rain water harvesting facility – Ground water recharge</li>
                          <li>PVC Overhead Tank based on per capita consumption from Sintex</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3Twell">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3Twell" aria-expanded="false" aria-controls="flush-collapse3Twell">
                        Fixtures
                      </button>
                    </h2>
                    <div id="flush-collapse3Twell" class="accordion-collapse collapse" aria-labelledby="flush-heading3Twell" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Kitchen sink &amp; Accessories: SS/Granite/Carysil – Rs.10,000/- per kitchen</li>
                          <li>CP &amp; Sanitary fixtures such as Wash Basin, WC, Taps and other accessories like bottle trap, gratings etc. : Jaquar/Hindware – Rs.35,000/- per bathroom attached toilet and Rs.15,000/- per &nbsp;powder room</li>
                          <li>Electrical Fixtures – Not included</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3theteen">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3theteen" aria-expanded="false" aria-controls="flush-collapse3theteen">
                        Doors
                      </button>
                    </h2>
                    <div id="flush-collapse3theteen" class="accordion-collapse collapse" aria-labelledby="flush-heading3theteen" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Main, Puja Door&nbsp; –&nbsp; Teak&nbsp; Wood&nbsp; frame&nbsp; &amp;&nbsp; shutter&nbsp; including&nbsp; hardware – Rs.50,000/- per door</li>
                          <li>Internal Door – Sal wood frame &amp; flush shutter including hardware – Rs.12,500/- per door</li>
                          <li>Internal door frames &amp; shutter – Painted</li>
                          <li>Main, Puja Door – Polished with PU finish spray</li>
                          <li>Bathroom Door –&nbsp; Frame&nbsp; &amp;&nbsp; shutter&nbsp; including&nbsp; hardware – &nbsp;Rs.10,000/- per door</li>
                          <li>Door Hardware – Ozone/Europa/Dorma make</li>
                          <li>Door Hinges – SS hinges</li>
                          <li>Door &amp; Door&nbsp; Frame&nbsp; cost&nbsp; –&nbsp; Inclusive of planing, rebate, transportation etc.</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3TFour">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3TFour" aria-expanded="false" aria-controls="flush-collapse3TFour">
                        windows
                      </button>
                    </h2>
                    <div id="flush-collapse3TFour" class="accordion-collapse collapse" aria-labelledby="flush-heading3TFour" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>UPVC windows – 5mm clear glass with MS Grills</li>
                        </ul>
                        <p>OR</p>
                        <ul>
                          <li>Wooden windows –&nbsp; Sal wood&nbsp; Frame (5”x3”)&nbsp; &amp;&nbsp; Honne Shutter (1.5”&nbsp; thickness) with clear glass and grill rods</li>
                          <li>Basic price: uPVC with MS grill or wooden including hardware – Rs.750/- per Sqft</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3TFive">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3TFive" aria-expanded="false" aria-controls="flush-collapse3TFive">
                        Fabrication
                      </button>
                    </h2>
                    <div id="flush-collapse3TFive" class="accordion-collapse collapse" aria-labelledby="flush-heading3TFive" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Main Gate: Designer Gate with Shera boards – Rs. 400/- per Sqft</li>
                          <li>External staircase &amp; balcony railing: MS Railing – Rs. 180/- per Sqft</li>
                          <li>Internal staircase railing: SS Railing of Rs.300/- per Sqft</li>
                          <li>Utility grill – Ground floor home only</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3TSix">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3TSix" aria-expanded="false" aria-controls="flush-collapse3TSix">
                        Compound Wall
                      </button>
                    </h2>
                    <div id="flush-collapse3TSix" class="accordion-collapse collapse" aria-labelledby="flush-heading3TSix" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Compound wall – Solid block walls of 4” thickness to suit the elevation</li>
                          <li>Excavation, Foundation, PCC,&nbsp; Size&nbsp; Stone&nbsp; Masonry&nbsp; with&nbsp; DPC&nbsp; course above SSM</li>
                          <li>Height of Compound wall– 5 Ft above Plinth level</li>
                          <li>Compound wall – Plastered &amp; Painted</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3TSeven">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3TSeven" aria-expanded="false" aria-controls="flush-collapse3TSeven">
                        Elevation
                      </button>
                    </h2>
                    <div id="flush-collapse3TSeven" class="accordion-collapse collapse" aria-labelledby="flush-heading3TSeven" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>This proposal includes a luxurious elevation</li>
                          <li>A 3D elevation is presented for client to choose from &amp; design further</li>
                          <li>Elevation budget – 1 % of the Project value.</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3TEight">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3TEight" aria-expanded="false" aria-controls="flush-collapse3TEight">
                        Exclusions & Incidentals
                      </button>
                    </h2>
                    <div id="flush-collapse3TEight" class="accordion-collapse collapse" aria-labelledby="flush-heading3TEight" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>A design if needed to be modified more than thrice shall be charged for accordingly</li>
                          <li>Government fees for the liaison bodies to be paid directly by the client</li>
                          <li>The complete fee payment for plan sanction in Panchayat limits need to be borne by client</li>
                          <li>Assuming the site &amp; road level to be equal, if the ground level needs to be raised above 18”, it shall be charged for accordingly</li>
                          <li>Hard rock/soft rock excavation, if it arises shall be charged for accordingly</li>
                          <li>Buying new earth/sending existing earth for excavation/backfilling purpose shall be charged on actual</li>
                          <li>Basic price of Cement is Rs.380/- per bag &amp; Steel is Rs.55,000/- per MT. Any increase in these prices shall be charged accordingly</li>
                          <li>Any work not mentioned within this package or outside the site boundary shall be charged for accordingly</li>
                          <li>Percentage of openings for doors &amp; windows – 27% Maximum</li>
                          <li>One point of Electricity &amp; water needs to be provided by the client free of cost to facilitate construction</li>
                          <li>SBC of soil is considered at 180 KN/M2 and depth of the foundation considered at 5 Feet, any increase in depth shall be charged accordingly on actuals upon site conditions</li>
                          <li>If there is constraints in material delivery or socking place and materials to be order in small quantities, the additional charges for the same shall be charged on actuals</li>
                          <li>A specific area to construct labour and material shed needs to be provided within 50 feet from the construction place by the client</li>
                          <li>Any road cutting charges for the connection of Electricity and Sewage lines is not included, such works shall be charged additionally</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3TNine">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3TNine" aria-expanded="false" aria-controls="flush-collapse3TNine">
                        Payment Structure
                      </button>
                    </h2>
                    <div id="flush-collapse3TNine" class="accordion-collapse collapse" aria-labelledby="flush-heading3TNine" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>A customized payment schedule will be shared based on the project specifications.</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-heading3Twenty">
                      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapse3Twenty" aria-expanded="false" aria-controls="flush-collapse3Twenty">
                        Warranty and Guarantee
                      </button>
                    </h2>
                    <div id="flush-collapse3Twenty" class="accordion-collapse collapse" aria-labelledby="flush-heading3Twenty" data-bs-parent="#accordionFlushExample">
                      <div class="accordion-body"><div class="packagescontent">
                        <ul>
                          <li>Construction guarantee – 1 Year ; Waterproofing warranty – 10 Years</li>
                          <li>Guarantee &amp; warranty is provided on Stamp paper</li>
                        </ul>
                      </div></div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <a
              href="#contact"
              class="p2ButtonStyle">Get a custom quote</a>
          </div>
        </div>
      </div>
    </>
  )
}